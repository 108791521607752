
import firebase from '../Firestore';


const db = firebase.firestore();




export async function getGeneralStats(id) {
    return await db.collection('stats').doc('general').get()
        .then(ref => {
            const data = ref.data();
            console.log({ data });
            return data;
        }).catch((e) => console.error(e));
}



export async function increaseCardAmountStats() {
    const properties = {
        lastCardCreatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        cardAmount: firebase.firestore.FieldValue.increment(1)
    }

    return await db.collection('stats').doc('general').set(properties, { merge: true }).then((ref) => {
        console.log('successfully updated! properties:', properties);
    });
}

export async function increaseVisitsStats() {
    const properties = {
        lastVisitAt: firebase.firestore.FieldValue.serverTimestamp(),
        homeVisits: firebase.firestore.FieldValue.increment(1)
    }

    return await db.collection('stats').doc('general').set(properties, { merge: true }).then((ref) => {
        console.log('successfully updated! properties:', properties);
    });
}
