import React, { Component, lazy } from 'react';
import { Redirect } from 'react-router-dom'



import firebase from '../../Firestore';

import './Create.scss';

import Loader from '../Loader/Loader';
import { shortId } from '../../functions/shortId';

import { increaseCardAmountStats } from '../dbService';


const EditCard = lazy(() => import('../EditCard/EditCard'));

class Create extends Component {
    db = firebase.firestore();

    state = { redirect: false };



    async idExists(id, col) {
        return new Promise((resolve, reject) => {
            this.db.collection(col).doc(id).get()
                .then(cardRef => {
                    resolve(cardRef.exists);
                    // console.log('cardRef', cardRef);
                });
        })
    }

    async getUniqueIdInCollection(col, length = 5) {
        let isUnique = false;
        while (!isUnique) {
            const id = shortId(length);
            console.log('checking if unique', id);
            if (await this.idExists(id, col)) {
                console.log('is not unique:', id);
            } else {
                console.log('is unique:', id);
                isUnique = true;
                return id;
            }
        }
    }


    async createId() {
        const id = await this.getUniqueIdInCollection('cards');

        console.log('id', id);

        const placeholderCard = {
            'id': id,
            'text': {
                'title': 'אופירי',
                'content': `אילו היה באפשרותי לתת לך דבר אחד בלבד,

הייתי נותן לך את היכולת
לראות את עצמך כפי שאחרים רואים אותך,

ואז היית נוכח לדעת
עד כמה הנך אדם מיוחד במינו.`,
                'endingWords': 'אוהבת שקד'
            },
            'design': {
                'style': {
                    'class': 'flowers-1'
                },
                'sound': {
                    'file': ''
                },
                'animation': {
                    'title': 'jumping-letters-in',
                    'content': 'scale-letters-in'
                }
            }
        }

        console.log('placeholderCard', placeholderCard);

        const cardData = {};
        // const cardData = process.env.NODE_ENV === 'development' ? placeholderCard : {};

        this.db.collection('cards').doc(id).set(cardData).then(async (ref) => {
            console.log('card added');

            const cardEditKeyID = await this.getUniqueIdInCollection('card-edit-keys', 30);

            this.db.collection('card-edit-keys').doc(cardEditKeyID).set({
                'cardID': id,
                'cardEditKeyID': cardEditKeyID
            }).then((docRef) => {
                console.log('card-edit-key added');
                console.log('redirecting to edit page...');

                this.setState({ redirect: `/edit/${cardEditKeyID}` });
                // this.setState({ redirect: true });

            });
        });

        increaseCardAmountStats();

    }







    componentDidMount() {
        this.createId();
    }



    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <div>
                <Loader title="יוצר ברכה..." />

                {/* Why not load the EditCard page, so when its ready, it will show?: */}
                <EditCard hidden={true} />
            </div>
        );
    }

}

export default Create;