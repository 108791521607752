import React, { Component } from 'react'

import './loader.scss';

export default class Loader extends Component {


    render() {
        return (
            <div className={"fullheight middle grow " + (!this.props.enableMinHeight ? 'min-height-100vh' : '')}>
                <div className="loader-page text-center">
                    <div className="loader"></div>
                    <h2>{this.props.title || 'טוען...'}</h2>
                </div>
            </div>
        )
    }
}
